import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../components/HomePage.vue' // Corrected path
import ServicePage from '../components/ServicePage.vue' // Corrected path
import ContactPage from '../components/ContactPage.vue' // Corrected path
import MaterialsPage from '../components/MaterialsPage.vue' // Corrected path

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { title: "GEDE-Tech"}
  },
  {
    path: '/materials',
    name: 'materialsPage',
    component: MaterialsPage,
  },
  {
    path: '/service',
    name: 'servicePage',
    component: ServicePage,
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else {
      return { top: 0 }
    }
  },
})

export default router
