<template>
  <WorkInProgress></WorkInProgress>
</template>

<script>
  import WorkInProgress from './components/WorkInProgress.vue'

  export default {
    name: 'App',
    components: {
      WorkInProgress,
    },
    metaInfo: {
      title: 'GEDE-Tech',
    },
    meta: [
      {
        name: 'description',
        content:
          '3D-Druck Dienstleister für Kunststoffe. Additive Fertigung hochwertiger industrieller Bauteile und Baugruppen.',
      },
    ],
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
